@namespace svg url(http://www.w3.org/2000/svg);

@font-face {
  font-family: Tenez;
  src: url("./assets/fonts/Tenez/regular/Plau-Tenez-Regular.otf") format("opentype");
}

@font-face {
  font-family: Tenez;
  font-style: italic;
  src: url("./assets/fonts/Tenez/italic/Plau-Tenez-Italic.otf") format("opentype");
}

body {
  margin: 0;
  font-family: Tenez;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
