.cluster-image-title {
  font-size: 55pt;
  display: none;
}
@media (min-width: 640px) {
  .cluster-image-title {
    display: block;
  }
}

.cluster-board {
  width: 100%;
  height: 100%;
}

.right-to-cluster {
  animation: goRight 0.7s forwards cubic-bezier(0.4, 0, 0.2, 1),
    fadeIn cubic-bezier(0.4, 0, 0.2, 1) 0.8s;
}

.left-to-cluster {
  animation: goLeft 0.7s forwards cubic-bezier(0.4, 0, 0.2, 1),
    fadeIn cubic-bezier(0.4, 0, 0.2, 1) 0.8s;
}

.scale-from-cluster {
  transform: scale(0);
  animation: clusterScale 0.7s forwards cubic-bezier(0.4, 0, 0.2, 1),
    fadeIn cubic-bezier(0.4, 0, 0.2, 1) 0.8s;
  animation-delay: 100ms;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes goRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes goLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes clusterScale {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
