svg a {
  outline: none;
}

.Home {
  color: #b1a493;
  font-size: 12pt;
}

.Home-board a[href] {
  cursor: pointer;
}

.Home-board a[href] .Home-circle:hover {
  fill-opacity: 0.75;
}

.Home-circle {
  animation: Home-float-circle 10s steps(250) infinite;
  transition-property: all;
  fill-opacity: 1;
  --transform-scale-x: 0;
  --transform-scale-y: 0;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

#circle1 {
  animation-delay: -5s;
}
#circle2 {
  animation-delay: -2s;
}
#circle3 {
  animation-delay: -1s;
}
#circle4 {
  animation-delay: -3s;
}
#circle5 {
  animation-delay: -4s;
}
#circle6 {
  animation-delay: -5s;
}
#circle7 {
  animation-delay: -0.5s;
}

@keyframes Home-float-circle {
  0% {
    transform: translate(0px, 0px);
  }
  10% {
    transform: translate(-2px, -1px);
  }
  20% {
    transform: translate(0px, -0.5px);
  }
  30% {
    transform: translate(-1px, 0.5px);
  }
  40% {
    transform: translate(0px, 1.5px);
  }
  50% {
    transform: translate(2px, 1px);
  }
  60% {
    transform: translate(3px, 1px);
  }
  70% {
    transform: translate(1px, 0px);
  }
  70% {
    transform: translate(-1px, -0.5px);
  }
  80% {
    transform: translate(-2px, -1.5px);
  }
  90% {
    transform: translate(-3px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
